
  // Vars
  var
    navi,
    bottomnavi,
    height,
    treshold,
    lastScrollTop,
    scrollYpos

  // Define
  navi = $("#masthead");
  bottomnavi = $('.header-intro');
  height = $(navi).outerHeight();
  treshold = (navi.data('treshold')) ? parseInt( navi.data('treshold') ) : navi.outerHeight();
  lastScrollTop = 0;

  // Set Initial State
  $(bottomnavi).css('margin-top',height);

  // Trigger Window Resize
  $(window).resize( function() {
    height = $(navi).outerHeight();
    $(bottomnavi).css('margin-top',height);
  });

  // Trigger Scroll
  $(window).scroll(function(){
    scrollYpos = $(window).scrollTop();
    if ( (scrollYpos > lastScrollTop) && scrollYpos > treshold ){
      navi.addClass('is-sticky-hidden');
    } else {
      navi.removeClass('is-sticky-hidden');
    }
    lastScrollTop = scrollYpos;
  });



  // ACCORDION IS EMPTY
  $('.accordion-content').each( function() {
    if( $(this).text().length === 0 ) {
      var parent = $(this).parent();
      var text = parent.find('.accordion-title').text();
      parent.addClass('is-empty').find('.accordion-title').remove();
      parent.append('<span class="accordion-title"><h4>'+text+'</h4></span>');
    };
  });

  // Smooth Scrolling
  $('.menu a[href*=\\#]').on('click', function(e){

    e.preventDefault();
    console.log(e);
    var href = $(this).attr('href'),
        anchor = href.split('#'),
        offset = 0,
        $offCanvas = $('#mobile-menu'),
        scrollYpos = $(window).scrollTop();

    if( $( '#' + anchor[1]).length ) {

      offset = $( '#' + anchor[1] ).offset().top

      if( scrollYpos >= offset ) {
        offset = offset - $('#site-navigation').outerHeight()
      }

      if( $offCanvas.hasClass('is-open') ) {
        $offCanvas.foundation('close');
        setTimeout(function(){
          $('html, body').animate({
          scrollTop:offset
        },'slow');
        }, 400);
      } else {
        $('html, body').animate({
          scrollTop:offset
        },'slow');
      }

    } else {
      location.href = href;
    }



  });


